<template>
  <div id="result-wrapper">
    <div class="content-box">
      <div class="inner">
        <div class="card">
          <div class="result-content" v-show="waiting">
            <p>{{ $t('thirdPartyDeposit.failed.countDownMsg', { countDown: countDown }) }}</p>
          </div>
          <div class="result-content" v-if="failure">
            <img src="@/assets/images/dialog_false.png" alt />
            <p>{{ $t('thirdPartyDeposit.result.failureMsg') }}</p>
            <router-link :to="{ name: 'home' }" class="el-button btn-red">
              {{ $t('common.button.bkToHm') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiThirdPartyDepositResult } from '@/resource';

export default {
  name: 'thirdPartyDepositFailed',
  data() {
    return {
      waiting: true,
      failure: false,
      countDown: 30,
      excludeResultList: ['vload']
    };
  },
  methods: {
    waitingCountDown() {
      setInterval(() => {
        this.countDown--;
      }, 1000);
    },
    getPathName() {
      return window.location.pathname;
    },
    excludeResult(paymentType) {
      return this.excludeResultList.includes(paymentType);
    },
    getPaymentType() {
      let paymentType = this.getPathName().split('/')[1];
      if (paymentType === 'thirdPartyDepositFailed') paymentType = this.getPathName().split('/')[2];
      this.getResult(paymentType);
    },
    getResult(paymentType) {
      if (!this.excludeResult(paymentType)) {
        this.postApiThirdPartyDepositResult(paymentType);
      } else {
        this.showFailed();
      }
    },
    showFailed() {
      this.failure = true;
      this.waiting = false;
    },
    postApiThirdPartyDepositResult(paymentType) {
      apiThirdPartyDepositResult(paymentType, this.$route.query).finally(result => {
        this.showFailed();
      });
    }
  },
  mounted() {
    if (this.$route.meta.restrictApi) {
      this.showFailed();
    } else {
      this.waiting = true;
      this.waitingCountDown();
      this.getPaymentType();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/thirdPartyDepositResult.scss';
</style>
